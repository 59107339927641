<template>
  <div class="bedBox fatherHeight">
    <div class="bedList-serach flex-between">
      <div class="serach-left">
        <el-form
          :inline="true"
          :model="tableSearch"
          ref="searchForm"
          class="demo-form-inline w-144"
        >
          <el-input
            v-model="tableSearch.search_code"
            class="hospital w-208"
            placeholder="设备编码搜索"
          >
            <template #suffix>
              <i
                style="cursor: pointer"
                @click="getDataList"
                class="fa fa-search"
              ></i>
            </template>
          </el-input>
          <el-select
            class="hospital"
            v-model="tableSearch.hospital_area_id"
            @change="getDataList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            @change="getDataList"
            v-model="tableSearch.run_status"
            placeholder="请选择"
          >
            <el-option label="正常" :value="1"></el-option>
            <el-option label="维修" :value="2"></el-option>
            <el-option label="报废" :value="3"></el-option>
          </el-select>
        </el-form>
      </div>
      <div class="serach-right">
        <el-button @click="addBed" class="color-green" type="primary">
          <i class="fa fa-plus-circle"></i>新增
        </el-button>
        <!-- <el-button class="color-vice" type="primary">
          <i class="fa fa-arrow-up"></i>导入
        </el-button>
        <el-button class="color-vice" type="primary">
          <i class="fa fa-arrow-down"></i>导出
        </el-button> -->
      </div>
    </div>
    <div class="table" ref="tableBoxRef">
      <el-table
        v-loading="dataListLoading"
        :data="tableData"
        v-if="istable"
        size="small"
        border
        :max-height="tableHei"
      >
        <el-table-column fixed prop="machine_no" label="设备编码" width="120" />
        <el-table-column
          prop="machine_name"
          show-overflow-tooltip
          label="名称"
          width="120"
        />
        <el-table-column prop="hospital.name" label="所属院区" />
        <el-table-column
          prop="dict_machine_model_name"
          label="型号"
          width="110"
        />
        <el-table-column prop="bed.bed_no" label="绑定床位" />
        <el-table-column label="感染标记" show-overflow-tooltip width="200">
          <template #default="scope">
            <div v-if="scope.row.infect_flag_name">
              <el-tag
                style="margin-right: 6px"
                size="small"
                v-for="(item, index) in scope.row.infect_flag_name"
                :key="index"
                :type="item == '正常' ? '' : 'danger'"
              >
                {{ item }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="治疗模式" show-overflow-tooltip width="200">
          <template #default="scope">
            {{ scope.row.dict_treat_model_name.join(',') }}
          </template>
        </el-table-column>
        <el-table-column label="机器状态" width="80">
          <template #default="scope">
            <el-tag v-if="scope.row.run_status == 1">正常</el-tag>
            <el-tag type="warning" v-else-if="scope.row.run_status == 2">
              维修
            </el-tag>
            <el-tag v-else type="danger">报废</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="dict_product_addr_name"
          show-overflow-tooltip
          label="产地"
        />
        <el-table-column
          prop="dict_factory_name"
          show-overflow-tooltip
          label="厂家"
        />
        <el-table-column prop="out_no" show-overflow-tooltip label="出厂编号" />
        <el-table-column prop="price" label="价格" />
        <el-table-column prop="buy_time_name" label="购买日期" width="160" />
        <el-table-column prop="start_time_name" label="启用日期" width="160" />
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-button type="text" size="small" @click="modParient(scope.row)">
              <i class="txIons modify"></i>
            </el-button>
            <el-button type="text" size="small" @click="delParient(scope.row)">
              <i class="txIons del"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          :currentPage="pageData.currentPage"
          :page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <Add ref="addRef" @refreshDataList="getDataList"></Add>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, defineExpose, nextTick } from 'vue'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import Add from './add.vue'

const userInfo = localStorage.getItem('ms_userData')
const dataListLoading = ref(false)
const istable = ref(true)
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableSearch = reactive({
  spital_area_id: userInfo.hospital,
  run_status: 1,
})
const tableData = ref([])
const tableBoxRef = ref(null)
const tableHei = ref(0)

onMounted(async () => {
  istable.value = false
  tableHei.value = tableBoxRef.value.clientHeight - 40
  nextTick(() => {
    istable.value = true
  })
  await getHospitalList()
  initData()
})
const initData = () => {
  handleSearch()
}
defineExpose({
  initData,
})
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const getDataList = async () => {
  tableSearch.current = pageData.currentPage
  tableSearch.size = pageData.pageSize
  dataListLoading.value = true
  let res = await service.post('/api/machine/list', tableSearch)
  dataListLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.records
    pageData.totalSum = res.data.total
  }
}

const hospitalList = ref([])
const getHospitalList = async () => {
  let res = await service.post('/api/hospital/list', {
    parent_id: 0,
  })
  if (res.code === 0) {
    hospitalList.value = res.data
    if (!tableSearch.hospital_area_id) {
      tableSearch.hospital_area_id = res.data[0].id
    }
  }
}
const addRef = ref(null)
const addBed = () => {
  addRef.value.initData()
}
const modParient = (item) => {
  addRef.value.initData(item)
}
const delParient = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/machine/delete', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}
</script>

<style scoped lang="scss">
.bedList-serach {
  padding-top: 2px;
}
.table {
  height: calc(100% - 46px);
}
.hospital {
  margin-right: 10px;
}
.demo-form-inline .w-208 {
  width: 208px;
}
</style>
